import { Box } from "@smartrr/shared/components/primitives";
import React, { ReactNode, useEffect, useRef } from "react";

interface INumericTextField {
  children: ReactNode;
}

export function NumericTextField({ children }: INumericTextField): JSX.Element {
  const textFieldRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    textFieldRef.current?.addEventListener("wheel", e => e.preventDefault());

    return () => {
      textFieldRef.current?.removeEventListener("wheel", e => e.preventDefault());
    };
  }, []);

  return <Box ref={textFieldRef}>{children}</Box>;
}
