import { Checkbox, FormLayout, LegacyStack, Select, TextField } from "@shopify/polaris";
import { SellingPlanPricingPolicyAdjustmentType } from "@smartrr/shared/shopifyGraphQL/api";
import React from "react";
import styled from "styled-components";

import { NumericTextField } from "@vendor-app/app/_sharedComponents/NumericTextField";

import { PricingPolicyModel } from "../models";

const AdjustmentTypeOptions: {
  label: string;
  value: string | SellingPlanPricingPolicyAdjustmentType;
}[] = [
  { label: "Fixed discount", value: SellingPlanPricingPolicyAdjustmentType.FixedAmount },
  { label: "Percentage discount", value: SellingPlanPricingPolicyAdjustmentType.Percentage },
  { label: "Discounted price", value: SellingPlanPricingPolicyAdjustmentType.Price },
];

interface Props {
  sellingPlanNumber?: number;
  policy: PricingPolicyModel;
  onChangePolicy(policy: PricingPolicyModel): void;
}

const DiscountOrderNumberContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 30px;
`;

export function PricingPolicyForm({ sellingPlanNumber = 0, policy, onChangePolicy }: Props): JSX.Element {
  const { adjustmentType, adjustmentValue, afterCycle, isEnabled } = policy;
  return (
    <React.Fragment>
      {afterCycle !== 0 && (
        <FormLayout>
          <DiscountOrderNumberContainer>
            <Checkbox
              id={`pricing-policy__discount-incentive__change-discount-after-order_${sellingPlanNumber}`}
              label="Change discount after order #"
              checked={isEnabled}
              onChange={checked => onChangePolicy({ ...policy, isEnabled: checked })}
            />
          </DiscountOrderNumberContainer>
        </FormLayout>
      )}
      {!!isEnabled && (
        <FormLayout>
          <FormLayout.Group condensed>
            <LegacyStack vertical>
              {afterCycle !== 0 && (
                <TextField
                  id={`pricing-policy__discount-incentive__order-number_${sellingPlanNumber}`}
                  autoComplete="off"
                  label=""
                  labelHidden={true}
                  disabled={!isEnabled}
                  type="number"
                  value={isEnabled ? policy.afterCycle.toString() : ""}
                  min={1}
                  onChange={value => {
                    const regexpForNumberBiggerThanZero = /^\+?[1-9]\d*$/;
                    let newValue = 1;
                    if (regexpForNumberBiggerThanZero.test(value)) {
                      newValue = Number(value);
                    }
                    onChangePolicy({
                      ...policy,
                      afterCycle: newValue,
                    });
                  }}
                />
              )}
              <Select
                id={`pricing-policy__discount-incentive__change-discount-after-order_${
                  afterCycle !== 0
                }__discount-type_${sellingPlanNumber}`}
                label=""
                disabled={!isEnabled}
                options={AdjustmentTypeOptions}
                value={adjustmentType}
                onChange={value =>
                  onChangePolicy({
                    ...policy,
                    adjustmentType: value as SellingPlanPricingPolicyAdjustmentType,
                  })
                }
              />
            </LegacyStack>
            <NumericTextField>
              <TextField
                id={`pricing-policy__discount-incentive__change-discount-after-order_${
                  afterCycle !== 0
                }__discount_${sellingPlanNumber}`}
                autoComplete="off"
                label=""
                labelHidden={true}
                disabled={!isEnabled}
                min={0}
                type="number"
                value={adjustmentValue.toString()}
                prefix={adjustmentType === SellingPlanPricingPolicyAdjustmentType.Percentage ? "%" : "$"}
                onChange={value => onChangePolicy({ ...policy, adjustmentValue: Number(value) })}
              />
            </NumericTextField>
          </FormLayout.Group>
        </FormLayout>
      )}
    </React.Fragment>
  );
}
