// We actually might not need models as a separate module, we could have just facade module that
// decouples our data structures from Shopify data structures. Until we implement such facade
// we will put here our models and methods that convert them to/from Shopify models

import {
  SellingPlanPricingPolicyAdjustmentType,
  SellingPlanPricingPolicyInput,
  SellingPlanPricingPolicyValueInput,
} from "@smartrr/shared/shopifyGraphQL/api";

export enum PricingPolicyType {
  Fixed = "fixed",
  Recurring = "recurring",
}

export interface ProductOfferingSettings {
  id?: string;
  originalProductVariantId: string;
  isActive: boolean;
  exists: boolean;
  newProductVariantId: string;
  orderNumber: number;
  sequentialEndBehavior: string;
  sequentialProducts?: string[];
}

export interface PricingPolicyModel {
  policyType: PricingPolicyType;
  adjustmentType: SellingPlanPricingPolicyAdjustmentType;
  adjustmentValue: number;
  afterCycle: number;
  // Previously created policy can be disabled and upon saving will be removed from the selling plan
  isEnabled: boolean;
}

export function buildModelFromPricingPolicy(policy: SellingPlanPricingPolicyInput): PricingPolicyModel {
  const { policyType, policyData } = policy.fixed
    ? { policyType: PricingPolicyType.Fixed, policyData: { ...policy.fixed!, afterCycle: 0 } }
    : { policyType: PricingPolicyType.Recurring, policyData: policy.recurring! };

  const adjustmentValue =
    policyData.adjustmentType === SellingPlanPricingPolicyAdjustmentType.Percentage
      ? policyData.adjustmentValue?.percentage
      : policyData.adjustmentValue?.fixedValue;

  return {
    policyType,
    adjustmentType: policyData.adjustmentType!,
    afterCycle: policyData.afterCycle,
    adjustmentValue,
    isEnabled: true, // Any policy that is part of selling plan is enabled
  };
}

export function buildPricingPolicyFromModel(model: PricingPolicyModel): SellingPlanPricingPolicyInput {
  if (!model.isEnabled) {
    throw new Error("Pricing policy is not enabled");
  }

  let adjustmentValue: SellingPlanPricingPolicyValueInput;
  switch (model.adjustmentType) {
    case SellingPlanPricingPolicyAdjustmentType.Percentage: {
      adjustmentValue = { percentage: Number(model.adjustmentValue) };
      break;
    }

    case SellingPlanPricingPolicyAdjustmentType.Price:
    case SellingPlanPricingPolicyAdjustmentType.FixedAmount: {
      adjustmentValue = { fixedValue: model.adjustmentValue };
      break;
    }

    default: {
      const exhaustiveCheck: never = model.adjustmentType;
      return exhaustiveCheck;
    }
  }

  return {
    [model.policyType]: {
      adjustmentType: model.adjustmentType,
      adjustmentValue,
      ...(model.policyType === PricingPolicyType.Recurring ? { afterCycle: model.afterCycle } : {}),
    },
  };
}

export function buildPricingPoliciesFromModels(models: PricingPolicyModel[]): SellingPlanPricingPolicyInput[] {
  return models.filter(model => model.isEnabled).map(model => buildPricingPolicyFromModel(model));
}
