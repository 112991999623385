import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import React from "react";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { isFeatureAvailable } from "./featureAccess";

interface Props {
  feature: FeatureEnum;
  children?: React.ReactNode;
}

export function AuthorizedControl(props: Props): JSX.Element {
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const user = useSmartrrVendorSelector(state => state.auth.user);

  if (isFeatureAvailable(props.feature, user, activePlan)) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
  return <React.Fragment />;
}
