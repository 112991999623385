import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import {
  SellingPlanInput,
  SellingPlanPricingPolicyAdjustmentType,
  SellingPlanPricingPolicyInput,
} from "@smartrr/shared/shopifyGraphQL/api";
import React from "react";

import { PricingPolicyForm } from "./PricingPolicyForm";
import { AuthorizedControl } from "../../components/authorization/AuthorizedControl";
import { PricingPolicyType, buildModelFromPricingPolicy, buildPricingPoliciesFromModels } from "../models";

interface Props {
  sellingPlanNumber?: number;
  sellingPlan: SellingPlanInput;
  fixedPolicyOnly?: boolean; // If set to true, hides recurring policy
  onPoliciesUpdate(pricingPolicies: Array<SellingPlanPricingPolicyInput>): void;
}

export function PricingPolicies({
  sellingPlanNumber = 0,
  sellingPlan,
  fixedPolicyOnly = false,
  onPoliciesUpdate,
}: Props): JSX.Element {
  const policies = sellingPlan.pricingPolicies?.map(buildModelFromPricingPolicy) || [];

  const fixedPolicy = policies[0] || {
    policyType: PricingPolicyType.Fixed,
    adjustmentType: SellingPlanPricingPolicyAdjustmentType.Percentage,
    adjustmentValue: 0,
    afterCycle: 0,
    isEnabled: true,
  };

  const recurringPolicy = policies[1] || {
    policyType: PricingPolicyType.Recurring,
    adjustmentType: SellingPlanPricingPolicyAdjustmentType.Percentage,
    adjustmentValue: 0,
    afterCycle: 1,
    isEnabled: false, // By default recurring policy is disabled
  };
  if (fixedPolicyOnly) {
    // If we display form in mode for fixed policy only, then recurring policy (if it existed and was enabled)
    // should not be saved in the end
    recurringPolicy.isEnabled = false;
  }

  return (
    <React.Fragment>
      <PricingPolicyForm
        sellingPlanNumber={sellingPlanNumber}
        policy={fixedPolicy}
        onChangePolicy={updatedPolicy =>
          onPoliciesUpdate(buildPricingPoliciesFromModels([updatedPolicy, recurringPolicy]))
        }
      />
      {!fixedPolicyOnly && (
        <AuthorizedControl feature={FeatureEnum.SEQUENTIAL_SELLING_PLAN}>
          <PricingPolicyForm
            sellingPlanNumber={sellingPlanNumber}
            policy={recurringPolicy}
            onChangePolicy={updatedPolicy =>
              onPoliciesUpdate(buildPricingPoliciesFromModels([fixedPolicy, updatedPolicy]))
            }
          />
        </AuthorizedControl>
      )}
    </React.Fragment>
  );
}
